import React from 'react';
import Page from '../components/Page';
import withRoot from '../utils/withRoot';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Page title="Not Found">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Page>
    );
  }
}

export default withRoot(NotFoundPage);
